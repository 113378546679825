import 'bootstrap';
import '@splidejs/splide';
import Splide from '@splidejs/splide';


// Add .active class to the current nav link and add aria-current to links pointing to current page

  // Get the current page URL
  const currentPageUrl = window.location.href;

  // Get all the link elements
  const links = document.querySelectorAll('a');

  // Loop through all the link elements
  for (let i = 0; i < links.length; i++) {
    // Check if the href attribute matches the current page URL
    if (links[i].href === currentPageUrl) {
      // Add the active class to the link element with class name nav-link
      if (links[i].classList.contains('nav-link')) {
        links[i].classList.add('active');
      }
      // Add the aria-current="page" attribute to the link
      links[i].setAttribute('aria-current', 'page');
    }
  }


Splide.defaults = {
    autoWidth: true,
    speed: 1000,
    drag: true,
    snap: true,
    type   : 'loop',
    perPage: 5,
    pagination: false,
    focus: 'center',
    pauseOnHover: true,
    pauseOnFocus: true,
  }
var splideCheck = document.getElementsByClassName("splide").length;
if (splideCheck) {
    var main = new Splide(".splide");
    main.mount();
}